import Payload from "../constants/Payload";
import Utility from "../utility/Utility";
import {
  PRODUCTS,
  INTERVALS,
  SUBSCRIPTION_STATUS,
  CURRENCYLIST,
} from "../constants/Enum";
import PlanManager from "./PlanManager";
import DataParser from "../Helper/DataParser";
import { PLANS } from "../constants/Enum";
import CurrencyManager from "./CurrencyManager";
import Subscription from "../services/subscription";
import BillingManager from "./BillingManager";
import {
  PAYMENT_GATEWAY,
  DEFAULT_PAYMENT_GATEWAY,
} from "../constants/PaymentGateway";
import UserManager from "./UserManager";

class SubscriptionManager {
  static subscriptionDetails = null;
  static currentSubscription = [];
  static invoices = { failed: [], paid: [] };
  static get() {
    return SubscriptionManager.subscriptionDetails;
  }
  static set(data) {
    SubscriptionManager.subscriptionDetails = data;
  }
  static setCurrentSubscription(data) {
    this.currentSubscription = [];
    if (!Utility.isEmpty(data)) {
      data[Payload.PLAN.AIO][Payload.PLAN_DETAILS.PRODUCT] = PRODUCTS.AIO;
      this.currentSubscription.push(data[Payload.PLAN.AIO]);

      data[Payload.PLAN.CRM][Payload.PLAN_DETAILS.PRODUCT] = PRODUCTS.CRM;
      this.currentSubscription.push(data[Payload.PLAN.CRM]);

      data[Payload.PLAN.ERP][Payload.PLAN_DETAILS.PRODUCT] = PRODUCTS.ERP;
      this.currentSubscription.push(data[Payload.PLAN.ERP]);

      data[Payload.PLAN.PEOPLE][Payload.PLAN_DETAILS.PRODUCT] = PRODUCTS.PEOPLE;
      this.currentSubscription.push(data[Payload.PLAN.PEOPLE]);
    }
  }
  static getParsedCurrentSubscription() {
    let dataToReturn = [];
    this.currentSubscription.forEach((sub) => {
      let plan_updated = !Utility.isEmpty(sub[Payload.PLAN_DETAILS.NAME])
        ? sub[Payload.PLAN_DETAILS.NAME]
        : PlanManager.getDefaultPlan();
      plan_updated = PlanManager.getParsedPlan(plan_updated);
      let chargedDateIsLifetime =
        sub[Payload.PLAN_DETAILS.NAME] === PLANS.PROFESSIONAL_A ||
        sub[Payload.PLAN_DETAILS.NAME] === PLANS.PROFESSIONAL_I
          ? true
          : false;
      dataToReturn.push({
        product: sub[Payload.PLAN_DETAILS.PRODUCT],
        plan: plan_updated,
        interval: sub[Payload.PLAN_DETAILS.INTERVAL]
          ? INTERVALS[sub[Payload.PLAN_DETAILS.INTERVAL].toUpperCase()]
          : PlanManager.getDefaultInterval(),
        status: this.getSubscriptionStatus(sub),
        trialDays: sub[Payload.PLAN_DETAILS.TRIAL_DAYS],
        planID: sub[Payload.PLAN_DETAILS.PLAN_ID],
        currency: sub[Payload.PLAN_DETAILS.CURRENCY].toUpperCase(),
        nextChargedDate: chargedDateIsLifetime
          ? "Lifetime"
          : sub[Payload.PLAN_DETAILS.NEXT_CHARGE_DATE],
      });
    });
    return dataToReturn;
  }
  static getSubscriptionStatus(data) {
    if (data) {
      if (
        !data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.IN_TRIAL]
      ) {
        return SUBSCRIPTION_STATUS.TRIAL_PENDING;
      } else if (
        data[Payload.PLAN_DETAILS.IN_TRIAL] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.IN_TRIAL;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        if (data[Payload.PLAN_DETAILS.PREV_PLAN_ID]) {
          return SUBSCRIPTION_STATUS.CANCELLED;
        }
        return SUBSCRIPTION_STATUS.TRIAL_ENDED;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.ACTIVE;
      }
    }
  }

  static getSubscriptionStatusForNewERP(data) {
    if (data) {
      if (SubscriptionManager.isMRPEnabled()) {
        if (
          !data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
          !data[Payload.PLAN_DETAILS.IN_TRIAL]
        ) {
          return SUBSCRIPTION_STATUS.TRIAL_PENDING;
        } else if (data[Payload.PLAN_DETAILS.TRIAL_OVER]) {
          if (data[Payload.PLAN_DETAILS.PREV_PLAN_ID]) {
            return SUBSCRIPTION_STATUS.CANCELLED;
          }
          return SUBSCRIPTION_STATUS.TRIAL_ENDED;
        } else {
          return SUBSCRIPTION_STATUS.TRIAL_PENDING;
        }
      }
      if (
        !data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.IN_TRIAL]
      ) {
        return SUBSCRIPTION_STATUS.TRIAL_PENDING;
      } else if (
        data[Payload.PLAN_DETAILS.IN_TRIAL] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.IN_TRIAL;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        if (data[Payload.PLAN_DETAILS.PREV_PLAN_ID]) {
          return SUBSCRIPTION_STATUS.CANCELLED;
        }
        return SUBSCRIPTION_STATUS.TRIAL_ENDED;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.ACTIVE;
      }
    }
  }

  static getSubscriptionStatusForNewMRP(data) {
    if (data) {
      if (!SubscriptionManager.isMRPEnabled()) {
        if (
          !data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
          !data[Payload.PLAN_DETAILS.IN_TRIAL]
        ) {
          return SUBSCRIPTION_STATUS.TRIAL_PENDING;
        } else if (data[Payload.PLAN_DETAILS.TRIAL_OVER]) {
          if (data[Payload.PLAN_DETAILS.PREV_PLAN_ID]) {
            return SUBSCRIPTION_STATUS.CANCELLED;
          }
          return SUBSCRIPTION_STATUS.TRIAL_ENDED;
        } else {
          return SUBSCRIPTION_STATUS.TRIAL_PENDING;
        }
      }
      if (
        !data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.IN_TRIAL]
      ) {
        return SUBSCRIPTION_STATUS.TRIAL_PENDING;
      } else if (
        data[Payload.PLAN_DETAILS.IN_TRIAL] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.IN_TRIAL;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        if (data[Payload.PLAN_DETAILS.PREV_PLAN_ID]) {
          return SUBSCRIPTION_STATUS.CANCELLED;
        }
        return SUBSCRIPTION_STATUS.TRIAL_ENDED;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.ACTIVE;
      }
    }
  }

  static getSubscriptionStatusForMRP(data) {
    if (data) {
      if (!SubscriptionManager.isMRPEnabled()) {
        return SUBSCRIPTION_STATUS.TRIAL_PENDING;
      }

      if (
        !data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.IN_TRIAL]
      ) {
        return SUBSCRIPTION_STATUS.TRIAL_PENDING;
      } else if (
        data[Payload.PLAN_DETAILS.IN_TRIAL] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.IN_TRIAL;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        !data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        if (data[Payload.PLAN_DETAILS.PREV_PLAN_ID]) {
          return SUBSCRIPTION_STATUS.CANCELLED;
        }
        return SUBSCRIPTION_STATUS.TRIAL_ENDED;
      } else if (
        data[Payload.PLAN_DETAILS.TRIAL_OVER] &&
        data[Payload.PLAN_DETAILS.PLAN_ID]
      ) {
        return SUBSCRIPTION_STATUS.ACTIVE;
      }
    }
  }

  static isAIOProductActive() {
    return SubscriptionManager.isProductActive(PRODUCTS.AIO);
  }
  static isAnyProductActive() {
    if (
      SubscriptionManager.isProductActive(PRODUCTS.AIO) ||
      SubscriptionManager.isProductActive(PRODUCTS.ERP) ||
      SubscriptionManager.isProductActive(PRODUCTS.CRM) ||
      SubscriptionManager.isProductActive(PRODUCTS.PEOPLE)
    ) {
      return true;
    }
    return false;
  }
  static isAnyProductInTrial() {
    if (
      SubscriptionManager.isProductInTrial(PRODUCTS.AIO) ||
      SubscriptionManager.isProductInTrial(PRODUCTS.ERP) ||
      SubscriptionManager.isProductInTrial(PRODUCTS.CRM) ||
      SubscriptionManager.isProductInTrial(PRODUCTS.PEOPLE)
    ) {
      return true;
    }
    return false;
  }

  static isAnyProductsTrialOver() {
    if (
      SubscriptionManager.isProductTrialOver(PRODUCTS.AIO) ||
      SubscriptionManager.isProductTrialOver(PRODUCTS.ERP) ||
      SubscriptionManager.isProductTrialOver(PRODUCTS.CRM) ||
      SubscriptionManager.isProductTrialOver(PRODUCTS.PEOPLE)
    ) {
      return true;
    }
    return false;
  }

  static isProductTrialOver(product) {
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.TRIAL_ENDED &&
        obj.product === product.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }

  static isAnyProductExpired() {
    if (
      SubscriptionManager.isProductExpired(PRODUCTS.AIO) ||
      SubscriptionManager.isProductExpired(PRODUCTS.ERP) ||
      SubscriptionManager.isProductExpired(PRODUCTS.CRM) ||
      SubscriptionManager.isProductExpired(PRODUCTS.PEOPLE)
    ) {
      return true;
    }
    return false;
  }

  static isProductExpired(product) {
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.EXPIRED &&
        obj.product === product.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }

  static isAnyProductCancelled() {
    if (
      SubscriptionManager.isProductCancelled(PRODUCTS.AIO) ||
      SubscriptionManager.isProductCancelled(PRODUCTS.ERP) ||
      SubscriptionManager.isProductCancelled(PRODUCTS.CRM) ||
      SubscriptionManager.isProductCancelled(PRODUCTS.PEOPLE)
    ) {
      return true;
    }
    return false;
  }

  static isProductCancelled(product) {
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.CANCELLED &&
        obj.product === product.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }

  static isProductActiveOrInTrial(product = [PRODUCTS.AIO]) {
    return product.some(
      (data) => this.isProductActive(data) || this.isProductInTrial(data)
    );
  }
  static isProfessionalPlanActive(product = [PRODUCTS.AIO]) {
    return product.some(
      (data) =>
        this.isPlanActive(data, PLANS.PROFESSIONAL, INTERVALS.MONTHLY) ||
        this.isPlanActive(data, PLANS.PROFESSIONAL, INTERVALS.YEARLY) ||
        this.isProductInTrial(data, PLANS.PROFESSIONAL, INTERVALS.MONTHLY) ||
        this.isPlanInTrial(data, PLANS.PROFESSIONAL, INTERVALS.YEARLY)
    );
  }
  static isProductInTrial(product) {
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.IN_TRIAL &&
        obj.product === product.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }

  static isProductActive(product) {
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.ACTIVE &&
        obj.product === product.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }

  static isPlanActive(product, plan, interval) {
    let plan_updated = PlanManager.getParsedPlan(plan);
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.ACTIVE &&
        obj.product === product.toLowerCase() &&
        obj.plan === plan_updated &&
        obj.interval === interval.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }
  static isPlanInTrial(product, plan, interval) {
    let plan_updated = PlanManager.getParsedPlan(plan);
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let activeSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.IN_TRIAL &&
        obj.product === product.toLowerCase() &&
        obj.plan === plan_updated &&
        obj.interval === interval.toLowerCase()
      );
    });
    return activeSubscription.length > 0;
  }
  static isPlanTrialPending(product) {
    let myCurrentSubscriptions =
      SubscriptionManager.getParsedCurrentSubscription();
    let pendingTrialSubscription = myCurrentSubscriptions.filter((obj) => {
      return (
        obj.status === SUBSCRIPTION_STATUS.TRIAL_PENDING &&
        obj.product === product.toLowerCase()
      );
    });
    return pendingTrialSubscription.length > 0;
  }

  static getInvoices() {
    return this.invoices;
  }
  static setInvoices(data, paid) {
    if (!data) data = [];

    data.forEach(function (element) {
      element["ChargeDate"] = DataParser.getDateFormatted(
        new Date(element.ChargeDate)
      );
    });

    data.sort(function (a, b) {
      return new Date(b.ChargeDate) - new Date(a.ChargeDate);
    });

    if (paid) {
      this.invoices.paid = data;
    } else {
      this.invoices.failed = data;
    }
  }
  static getProductsToShow() {
    let isCountryUSorIN = UserManager.checkIfTenantIsUSOrIN();

    let productToShow = [];
    const PRODUCT_ARRAY = [
      PRODUCTS.AIO,
      PRODUCTS.CRM,
      PRODUCTS.ERP,
      PRODUCTS.PEOPLE,
      PRODUCTS.CRM_PLUS,
    ];
    productToShow.push(PRODUCTS.CONSOLE);

    let displayPlusProduct = SubscriptionManager.displayPlusProduct();

    if (SubscriptionManager.isProductActiveOrInTrial([PRODUCTS.AIO])) {
      if (displayPlusProduct) {
        if (isCountryUSorIN) {
          productToShow.push(PRODUCTS.BOOKS_PLUS);
        } else {
          productToShow.push(PRODUCTS.ERP);
        }
        productToShow.push(PRODUCTS.PEOPLE_PLUS);
      } else {
        productToShow.push(PRODUCTS.ERP);
        productToShow.push(PRODUCTS.CRM);
        productToShow.push(PRODUCTS.PEOPLE);
      }
      productToShow.push(PRODUCTS.CRM_PLUS);
    } else {
      if (SubscriptionManager.isProductActiveOrInTrial([PRODUCTS.ERP])) {
        if (isCountryUSorIN && displayPlusProduct) {
          productToShow.push(PRODUCTS.BOOKS_PLUS);
        } else {
          productToShow.push(PRODUCTS.ERP);
        }
      }
      if (SubscriptionManager.isProductActiveOrInTrial([PRODUCTS.CRM])) {
        productToShow.push(PRODUCTS.CRM);
        productToShow.push(PRODUCTS.CRM_PLUS);
      }
      if (SubscriptionManager.isProductActiveOrInTrial([PRODUCTS.PEOPLE])) {
        if (displayPlusProduct) {
          productToShow.push(PRODUCTS.PEOPLE_PLUS);
        } else {
          productToShow.push(PRODUCTS.PEOPLE);
        }
      }
    }

    if (SubscriptionManager.isProfessionalPlanActive(PRODUCT_ARRAY)) {
      //   productToShow.push(PRODUCTS.WORKFLOW_ENGINE);
      // productToShow.push(PRODUCTS.SHOP);
      productToShow.push(PRODUCTS.REPORT_BUILDER);
    }
    return productToShow;
  }

  static areAllProductsActive() {
    return (
      SubscriptionManager.isProductActive(PRODUCTS.ERP) &&
      SubscriptionManager.isProductActive(PRODUCTS.CRM) &&
      SubscriptionManager.isProductActive(PRODUCTS.PEOPLE)
    );
  }

  static getCurrency(data) {
    switch (data["country"]["iso_code"]) {
      case "IN":
        return CURRENCYLIST.INR;
      case "ID":
        return CURRENCYLIST.IDR;
      case "MM":
      case "MY":
        return CURRENCYLIST.MYR;
      case "US":
      default:
        return CURRENCYLIST.USD;
    }
  }

  static getCountryCodeFromLocation() {
    Subscription.getCountryFromRequest().then(
      (res) => {
        if (Utility.isEmpty(PAYMENT_GATEWAY[res["country"]["iso_code"]])) {
          BillingManager.setPaymentGateway(DEFAULT_PAYMENT_GATEWAY);
        } else {
          BillingManager.setPaymentGateway(
            PAYMENT_GATEWAY[res["country"]["iso_code"]]
          );
        }

        const curr = SubscriptionManager.getCurrency(res);
        CurrencyManager.setCurrentCurrency(curr);

        const country = res["country"]["iso_code"];
        CurrencyManager.setCurrentCountry(country);
      },
      () => {
        CurrencyManager.setCurrentCurrency(CURRENCYLIST.USD);
        return CURRENCYLIST.USD;
      }
    );
  }

  static isAnyProductSubscribed() {
    return (
      SubscriptionManager.isAnyProductActive() ||
      SubscriptionManager.isAnyProductInTrial() ||
      SubscriptionManager.isAnyProductExpired()
    );
  }

  static getCurrencySameAsActivePlanCurrency() {
    if (SubscriptionManager.isAnyProductSubscribed()) {
      if (
        SubscriptionManager.isProductActive(PRODUCTS.AIO) ||
        SubscriptionManager.isProductInTrial(PRODUCTS.AIO) ||
        SubscriptionManager.isProductExpired(PRODUCTS.AIO)
      ) {
        return SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO][
          Payload.PLAN_DETAILS.CURRENCY
        ].toUpperCase();
      } else if (
        SubscriptionManager.isProductActive(PRODUCTS.ERP) ||
        SubscriptionManager.isProductInTrial(PRODUCTS.ERP) ||
        SubscriptionManager.isProductExpired(PRODUCTS.ERP)
      ) {
        return SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
          Payload.PLAN_DETAILS.CURRENCY
        ].toUpperCase();
      } else if (
        SubscriptionManager.isProductActive(PRODUCTS.CRM) ||
        SubscriptionManager.isProductInTrial(PRODUCTS.CRM) ||
        SubscriptionManager.isProductExpired(PRODUCTS.CRM)
      ) {
        return SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM][
          Payload.PLAN_DETAILS.CURRENCY
        ].toUpperCase();
      } else if (
        SubscriptionManager.isProductActive(PRODUCTS.PEOPLE) ||
        SubscriptionManager.isProductInTrial(PRODUCTS.PEOPLE) ||
        SubscriptionManager.isProductExpired(PRODUCTS.PEOPLE)
      ) {
        return SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE][
          Payload.PLAN_DETAILS.CURRENCY
        ].toUpperCase();
      }
    } else {
      return CurrencyManager.getCurrentCurrency();
    }
  }

  static displayPlusProduct() {
    let plusProductsCutoffDate = new Date(
      process.env.REACT_APP_PLUS_PRODUCT_START_DATE
    ).getTime();
    let userSignUpDate = new Date(UserManager.getTenantCreatedDate()).getTime();
    let showPlusProducts = userSignUpDate > plusProductsCutoffDate;

    let enablePlusProduct =
      process.env.REACT_APP_ENABLE_PLUS_PRODUCTS === "true" ? true : false;

    if (showPlusProducts && enablePlusProduct) {
      return true;
    } else {
      return false;
    }
  }

  static getNewERPDisplayPlanName() {
    return SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
      Payload.PLAN_DETAILS.DisplayName
    ]?.toLowerCase();
  }

  static isMRPEnabled() {
    return SubscriptionManager.subscriptionDetails?.ProductsEnabled?.includes(
      PRODUCTS.MRP?.toUpperCase()
    );
  }

  static isProductEnabled(product) {
    return SubscriptionManager.subscriptionDetails?.ProductsEnabled?.includes(
      product?.toUpperCase()
    );
  }

  static showNewCreditBasedFlow() {
    let showCreditFlow = false;
    if (!Utility.isEmpty(SubscriptionManager.subscriptionDetails?.CreditsApplied)) {
      showCreditFlow = true
    } else if (this.currentSubscription.some((plan) => plan?.Name?.trim() !== '')) {
      if (this.currentSubscription.some((plan) => plan?.InTrial)) {
        showCreditFlow = true
      }
    } else {
      showCreditFlow = true
    }
    return showCreditFlow;
  }

  static showOldCardIfSubscriptionFromOldPlan(plan) {
    const subscriptionData = SubscriptionManager.subscriptionDetails;

    if (
      SubscriptionManager.isMRPEnabled() &&
      !Utility.isEmpty(subscriptionData[plan].Name) &&
      Utility.isEmpty(subscriptionData[plan].DisplayName)
    ) {
      return false;
    }

    if (
      !Utility.isEmpty(subscriptionData[plan].Name) &&
      Utility.isEmpty(subscriptionData[plan].DisplayName)
    ) {
      return true;
    }

    return false;
  }

  static anyOneOldPlanIsActive() {
    return (
      SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
        Payload.PLAN.AIO
      ) ||
      SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
        Payload.PLAN.ERP
      ) ||
      SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
        Payload.PLAN.CRM
      ) ||
      SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
        Payload.PLAN.PEOPLE
      )
    );
  }

  static getEmplyPlanState() {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }

    const subscriptionData = SubscriptionManager.subscriptionDetails;

    if (Utility.isEmpty(subscriptionData[Payload.PLAN.ERP].Name)) {
      return Payload.PLAN.ERP;
    } else if (Utility.isEmpty(subscriptionData[Payload.PLAN.CRM].Name)) {
      return Payload.PLAN.CRM;
    } else if (Utility.isEmpty(subscriptionData[Payload.PLAN.PEOPLE].Name)) {
      return Payload.PLAN.PEOPLE;
    } else if (Utility.isEmpty(subscriptionData[Payload.PLAN.AIO].Name)) {
      return Payload.PLAN.AIO;
    } else {
      return Payload.PLAN.AIO;
    }
  }

  static getPlanForNewERPCard() {
    const anyOneOldPlanActive = SubscriptionManager.anyOneOldPlanIsActive();
    let newERPPlan = anyOneOldPlanActive
      ? SubscriptionManager.subscriptionDetails &&
        SubscriptionManager.getEmplyPlanState()
      : SubscriptionManager.isSubscriptionEnabledForNewERPPlan()
      ? Payload.PLAN.ERP
      : Payload.PLAN.AIO;

    if (SubscriptionManager.isMRPEnabled()) {
      newERPPlan = Payload.PLAN.AIO;
    }

    return newERPPlan;
  }

  static getProductForNewERPCard() {
    const anyOneOldPlanActive = SubscriptionManager.anyOneOldPlanIsActive();
    let newERPProduct = anyOneOldPlanActive
      ? PRODUCTS.ERP
      : SubscriptionManager.isSubscriptionEnabledForNewERPPlan()
      ? PRODUCTS.ERP
      : PRODUCTS.AIO;
    if (SubscriptionManager.isMRPEnabled()) {
      newERPProduct = PRODUCTS.ERP;
    }
    return newERPProduct;
  }

  static isSubscriptionEnabledForNewERPPlan() {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }

    const subscriptionData = SubscriptionManager.subscriptionDetails;

    if (
      !Utility.isEmpty(subscriptionData[Payload.PLAN.ERP].DisplayName) ||
      !Utility.isEmpty(subscriptionData[Payload.PLAN.CRM].DisplayName) ||
      !Utility.isEmpty(subscriptionData[Payload.PLAN.PEOPLE].DisplayName)
    ) {
      return true;
    }

    return false;
  }

  static isNewERPPlan() {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }

    const subscriptionData = SubscriptionManager.subscriptionDetails;

    if (
      (!Utility.isEmpty(subscriptionData[Payload.PLAN.AIO].Name) &&
        !Utility.isEmpty(subscriptionData[Payload.PLAN.AIO].DisplayName)) ||
      (!Utility.isEmpty(subscriptionData[Payload.PLAN.ERP].Name) &&
        !Utility.isEmpty(subscriptionData[Payload.PLAN.ERP].DisplayName)) ||
      (!Utility.isEmpty(subscriptionData[Payload.PLAN.CRM].Name) &&
        !Utility.isEmpty(subscriptionData[Payload.PLAN.CRM].DisplayName)) ||
      (!Utility.isEmpty(subscriptionData[Payload.PLAN.PEOPLE].Name) &&
        !Utility.isEmpty(subscriptionData[Payload.PLAN.PEOPLE].DisplayName))
    ) {
      return true;
    }

    return false;
  }

  static erpPlan(plan) {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }
    return (
      SubscriptionManager.subscriptionDetails &&
      SubscriptionManager.subscriptionDetails[plan][Payload.PLAN_DETAILS.NAME]
    );
  }

  static erpPlanStatus(plan) {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }
    return SubscriptionManager.getSubscriptionStatusForNewERP(
      SubscriptionManager.subscriptionDetails &&
        SubscriptionManager.subscriptionDetails[plan]
    );
  }

  static mrpPlanStatus(plan) {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }
    return SubscriptionManager.getSubscriptionStatusForNewMRP(
      SubscriptionManager.subscriptionDetails &&
        SubscriptionManager.subscriptionDetails[plan]
    );
  }

  static erpPlanDaysRemaining(plan) {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }

    if (SubscriptionManager.isMRPEnabled()) {
      return 0;
    } else {
      return (
        SubscriptionManager.subscriptionDetails &&
        SubscriptionManager.subscriptionDetails[plan][
          Payload.PLAN_DETAILS.TRIAL_DAYS
        ]
      );
    }
  }

  static erpPlanInterval(plan) {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return;
    }
    return (
      SubscriptionManager.subscriptionDetails &&
      SubscriptionManager.subscriptionDetails[plan][
        Payload.PLAN_DETAILS.INTERVAL
      ]
    );
  }

  static getIndiaOrGlobalLocation() {
    if (SubscriptionManager.currentLocation === "IN") {
      return "IN";
    } else {
      return "US";
    }
  }
}

export default SubscriptionManager;
