import { DKDataGrid, DKLabel, INPUT_TYPE } from "deskera-ui-library";
import SideBarService from "../../services/sidebar";
import { roundOff } from "../../utility/Utility";

const CreditHistory = ({ creditData, isPopup }: any) => {

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title={!isPopup ? "Credit History" : ""}
        rows={creditData ?? []}
        width={!isPopup ? SideBarService.getContainerWidth() - 50 : 520}
        columns={COLUMN_CONFIG}
        buttons={[]}
        allowBulkOperation={false}
        allowColumnEdit={false}
        needShadow={false}
        needBorder={true}
        needTrailingColumn={!isPopup}
        needColumnIcons={false}
        allowColumnSort={false}
      />
    );
  };

  const getBlankGridView = () => {
    return (
      <div className="column parent-size justify-content-center align-items-center mb-l">
        <DKLabel text="No data found" className="fw-m text-gray" />
      </div>
    );
  };

  return (
    <div className={`column parent-width flex-1 bg-white ${!isPopup ? "p-r border-m border-radius-m mt-l" : ""}`}>
      {getDataGrid()}
      {creditData?.length === 0 && getBlankGridView()}
    </div>
  );
};

const COLUMN_CONFIG = [
  {
    id: "Code",
    key: "Code",
    name: "Code",
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
  },
  {
    id: "AppliedDate",
    key: "AppliedDate",
    name: "Applied Date",
    type: INPUT_TYPE.DATE,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
  },
  {
    id: "CreditsAvailable",
    key: "CreditsAvailable",
    name: "Credits Available",
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    renderer: (obj: any) => {
      return <DKLabel text={roundOff(obj?.rowData?.CreditsAvailable || 0)} className="row justify-content-end" />;
    },
  },
];

export default CreditHistory;
