import React, { Component } from "react";

import {
  DKIcon,
  DKLabel,
  DKButton,
  showLoader,
  DKIcons,
  removeLoader,
  showAlert,
  showToast,
  TOAST_TYPE,
  DKSpinner,
} from "deskera-ui-library";

import ic_cards from "../../assets/icons/ic_cards.png";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "../stripe/StripeForm";
import StripeApiManager from "../../managers/StripeApiManager";
import UserManager from "../../managers/UserManager";
import Subscription from "../../services/subscription";
import BillingManager from "../../managers/BillingManager";
import Utility from "../../utility/Utility";

import ic_edit from "../../assets/icons/ic_edit.png";
import { trackEventAC } from "../../managers/ActiveCampaignWrapper";
import { TRACK_EVENT } from "../../constants/Enum";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

/*
- isPopup
- onCardAdded
- onCancel
*/

class CreditCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardValid: false,
      cardDetails: props?.cardDetails,
      isCardAdded: false,
      didAddNewCardTapped: false,
      cardResponseReceived: true,
      isBookkeeper: this.props.isBookkeeper,
      userId: "",
      tenantId: "",
    };
  }

  componentDidMount() {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // let userID = !Utility.isEmpty(urlParams.get(URL_PARAMS.USER_ID))
    //   ? urlParams.get(URL_PARAMS.USER_ID)
    //   : "";

    // let tenantID = !Utility.isEmpty(urlParams.get(URL_PARAMS.TENANT_ID))
    //   ? urlParams.get(URL_PARAMS.TENANT_ID)
    //   : "";

    // this.setState({
    //   userId: userID,
    //   tenantId: tenantID,
    // });
  }

  getCardDetails(isNewCardAdded = false) {
    if (
      isNewCardAdded ||
      Utility.isEmpty(BillingManager.getDefaultPaymentCard())
    ) {
      Subscription.getPaymentMethods()
        .then((res) => {
          this.setCardValues();
          if (isNewCardAdded) {
            this.setState({
              isCardAdded: true,
              didAddNewCardTapped: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ cardResponseReceived: true });
        });
    } else {
      // if (this.state.userId === "" && this.state.tenantId === ""){
      this.setCardValues();
      // }
    }
  }

  setCardValues() {
    let cardInfo = BillingManager.getDefaultPaymentCard();
    this.setState({
      cardDetails: cardInfo,
      isCardAdded: cardInfo && cardInfo.Last4,
      cardResponseReceived: true,
    });
    if (cardInfo && cardInfo.Last4 && this.props.onCardAdded) {
      this.props.onCardAdded();
    }
  }

  render() {
    const { t } = this.props;
    return (
		<div
			className={`pl-l pr-l pt-l pb-l border-radius-s shadow-s bg-white column parent-width ${
				isMobileAppWebView() ? ' border-radius-r ' : ''
			}`}
		>
			<div className="row justify-content-between">
				<div className="parent-width row">
					<DKIcon src={ic_cards} className="mr-s" />
					<DKLabel
						text={'Add payment method'}
						className="fw-m fs-l"
					/>
				</div>
				<DKIcon
					src={DKIcons.ic_close}
					className={'ic-s cursor-hand'}
					style={{ opacity: 0.4 }}
					onClick={() => {
						this.props?.onCancel?.();
					}}
				/>
			</div>

			{this.state.isCardAdded && !this.state.didAddNewCardTapped && (
				<div className="column parent-width mt-l bg-gray1 border-m border-radius-s pt-r pb-r pl-r pr-r">
					<DKLabel
						text={
							(this.state.cardDetails &&
							this.state.cardDetails.CardBrand
								? this.state.cardDetails.CardBrand.toUpperCase()
								: '') +
							' ******* ' +
							(this.state.cardDetails &&
							this.state.cardDetails.Last4
								? this.state.cardDetails.Last4
								: '')
						}
					/>
				</div>
			)}

			{this.state.isCardAdded && !this.state.didAddNewCardTapped && (
				<DKButton
					title={t('EDIT_CARD')}
					className="bg-gray1 border-m text-whites fw-m mt-l"
					icon={ic_edit}
					onClick={() => {
						this.setState({
							didAddNewCardTapped: true,
						});
					}}
				/>
			)}
			{!this.state.cardResponseReceived && (
				<DKSpinner
					title={t('FETCHING_CARD_DETAILS')}
					className="mt-xl mb-l"
				/>
			)}

			{(!this.state.isCardAdded || this.state.didAddNewCardTapped) &&
				this.state.cardResponseReceived && (
					<div className="column parent-width mt-l">
						<Elements stripe={stripePromise}>
							<StripeForm
								checkoutTapped={this.state.checkoutTapped}
								onCardDetailsEnter={(isValid) =>
									this.setState({ isCardValid: isValid })
								}
								onPaymentInitialize={() => {}}
								onPaymentSetup={(res) =>
									this.onPaymentSetup(res)
								}
								onPaymentFail={(error) =>
									this.onPaymentFail(error)
								}
								user={UserManager?.getUserDetails()}
								tenantID={this.state.tenantId}
								userID={this.state.userId}
							/>
						</Elements>
					</div>
				)}
			{(!this.state.isCardAdded || this.state.didAddNewCardTapped) &&
				this.state.cardResponseReceived && (
					<DKButton
						title={'Add card'}
						className={`row justify-content-center text-white fw-m text-wrap-none text-align-center mt-l parent-width ${
							this.state.isCardValid ? 'bg-blue' : 'bg-gray'
						}`}
						style={{ width: '100%' }}
						disabled={this.state.isCardValid ? false : true}
						onClick={() => {
							if (this.state.isCardValid) {
								this.setState(
									{
										checkoutTapped: false,
									},
									() => {
										this.setState({
											checkoutTapped: true,
											isCardValid: false,
										});
									}
								);

								showLoader(
									this.state.didAddNewCardTapped
										? t('UPDATING_CARD')
										: t('ADDING_CARD')
								);
							}
						}}
					/>
				)}
		</div>
	);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  setPaymentMethod = (payment_method) => {
    StripeApiManager.setPaymentMethod(
      payment_method,
      this.state.tenantId,
      this.state.userId,
      (data) => {
        showAlert(t("PAYMENT_RECEIVED"), t("YOUR_SUBSCRITION_IS_ACTIVE_NOW"));
      },
      (error) => {
        // this.handleError(error);
        showToast(error, TOAST_TYPE.FAILURE);
      }
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onPaymentSetup = (paymentMethod) => {
    removeLoader();
    showAlert(
      t("CARD_ADDED_SUCCESSFULLY"),
      t("YOUR_CREDIT_CARD_HAS_BEEN_ADDED_FOR_SUBSCRIPTION")
    );
    trackEventAC(
      {
        event: TRACK_EVENT.ADD_CARD,
        eventData: "Card added successfully",
        email: UserManager.getUserEmail(),
      },
      () => { })

    this?.props?.onCardAdded();
  };

  onPaymentFail = (error) => {
    removeLoader();
    showToast(error, TOAST_TYPE.FAILURE);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default withTranslation()(CreditCardView);
