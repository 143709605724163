import React, { Component } from "react";

import { DKLabel, DKButton, DKTooltipWrapper } from "deskera-ui-library";

import ic_company from "../../assets/icons/ic_company.png";
import ic_user from "../../assets/icons/ic_user_2.png";
import ic_edit from "../../assets/icons/pencil.png";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { USER_STATUS } from "../../constants/Enum";
import UserManager from "../../managers/UserManager";
import CompanyDetailManager from "../../managers/CompanyDetailManager";
import { getCapitalized } from "../../utility/Utility";
import User from "../../services/user";
import { withTranslation } from "react-i18next";
import ChangeEmailPopup from "./ChangeEmailPopup";
import { t } from "i18next";
class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserManager?.getUserDetails(),
      organisation: CompanyDetailManager.getCompanyDetails(),
      displayChangeEmailPopup: false,
    };
  }

  componentDidMount() {
    if (UserManager.getUserID() !== null) {
      User.getUserInfo({ id: UserManager.getUserID() }).then((res) => {
        if (res.content[0] !== undefined) {
          let userData = UserManager?.getUserDetails();
          userData.phone = res.content[0].contact;
          userData.name =
            res.content[0].firstName + " " + res.content[0].lastName;
          UserManager.setUserDetails(userData);
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              phone: res.content[0].contact,
              name: res.content[0].firstName + " " + res.content[0].lastName,
            },
          }));
        }
      });
    }
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      user: UserManager.getUserDetails(),
      organisation: nextProp.organisation,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width row row-responsive align-items-start">
        {this.getUserDetails()}
        <div
          className="bg-line align-self-center display-only-web"
          style={{ width: 2, height: 100, marginRight: "3%" }}
        />
        {this.getOrganisationDetails()}
        {this.state.displayChangeEmailPopup && this.getChangeEmailPopup()}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getUserDetails() {
    return (
      <div className="column width-50">
        <DKLabel text={t("USER_DETAILS")} className="fw-m fs-m" />
        <div
          className="mt-xl row  row-responsive mb-l"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div
            className="circle column bg-gray1 border-m ml-l-only-web align-items-center justify-content-center fs-xl fw-b text-gray shadow-s"
            style={{ width: 60, height: 60 }}
          >
            <img src={ic_user} alt="" style={{ width: "50%", opacity: 0.6 }} />
          </div>
          <div className="column ml-l">
            <DKLabel
              className="fw-m fs-m"
              text={
                this.state.user ? getCapitalized(this.state.user.name) : "-"
              }
            />
            <DKTooltipWrapper
              content={
                this.state.user?.email.length >= 30
                  ? t("EMAIL") + (this.state.user ? this.state.user.email : "-")
                  : ""
              }
              tooltipClassName="bg-gray1"
            >
              <div
                style={{ display: "flex" }}
                className="content-holder user-email"
              >
                <DKLabel
                  className={`text-dark-gray mt-xs ${
                    this.state.user.email.length >= 30 ? "welcome-message" : ""
                  }`}
                  style={{ whiteSpace: "" }}
                  text={
                    t("EMAIL") + (this.state.user ? this.state.user.email : "-")
                  }
                />
                <div className="hide-block">
                  <DKButton
                    icon={ic_edit}
                    className=""
                    style={{ paddingLeft: "2px", height: 0, marginTop: "2px" }}
                    onClick={() =>
                      this.setState({ displayChangeEmailPopup: true })
                    }
                  />
                </div>
              </div>
            </DKTooltipWrapper>
            <DKLabel
              className="text-dark-gray mt-xs"
              text={
                t("PHONE") +
                (this.state.user && this.state.user.phone
                  ? this.state.user.phone
                  : t("NA"))
              }
            />
            <DKLabel
              className="text-orange bg-chip-oranged border-orange border-radius-l pl-s pr-s pt-xs pb-xs mt-r fw-m text-green"
              text={UserManager.getUserOrgRole()}
            />
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getOrganisationDetails() {
    return (
      <div className="column width-50">
        <DKLabel text={t("COMPANY_DETAILS")} className="fw-m fs-m" />
        <div
          className="mt-xl row row-responsive mb-l"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div
            className="circle column bg-gray1 border-m ml-l-only-web align-items-center justify-content-center fs-xl fw-b text-gray shadow-s"
            style={{ width: 60, height: 60 }}
          >
            <img
              src={ic_company}
              alt=""
              style={{ width: "55%", opacity: 0.5 }}
            />
          </div>
          <div className="column ml-l">
            <DKLabel
              className="fw-m fs-m"
              text={
                this.state.user && this.state.user.tenantName
                  ? getCapitalized(this.state.user.tenantName)
                  : "-"
              }
            />
            <DKLabel
              className="text-dark-gray mt-xs"
              text={
                t("LOCATION") +
                // (this.state.user && this.state.user.country
                //   ? this.state.user.country
                //   : "NA")
                (this.state.user && this.state.user.country
                  ? this.state.user.country
                  : CompanyDetailManager.getCompanyDetails().country || t("NA"))
              }
            />
            <DKLabel
              className="text-dark-gray mt-xs"
              text={
                t("PHONE") +
                (CompanyDetailManager.getCompanyDetails().contacts?.phone
                  ? CompanyDetailManager.getCompanyDetails().contacts.phone
                  : t("NA"))
              }
            />
            <div
              className="row align-items-center mt-m"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <DKLabel
                className="bg-chip-greens border-green border-radius-l pl-s pr-s pt-xs pb-xs mt-xs mr-r fw-m text-green"
                text={USER_STATUS.ACTIVE}
              />
              {(UserManager.isUserAdmin() || UserManager.isUserOwner()) && (
                <DKButton
                  title={t("MORE_DETAILS")}
                  className="bg-gray2 border-m  mt-xs  fw-m text-wrap-none"
                  onClick={() => {
                    RouteManager.navigateToPage(PAGE_ROUTES.SETTINGS);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getChangeEmailPopup() {
    return <ChangeEmailPopup onCancel={this.hideChangeEmailPopup} />;
  }

  hideChangeEmailPopup = () => {
    this.setState({ displayChangeEmailPopup: false });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default withTranslation()(UserDetails);
