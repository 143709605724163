import {
  DKLabel,
  DKLine,
  getDateAsString,
  DKDataGrid,
  INPUT_TYPE,
} from "deskera-ui-library";
import SideBarService from "../../services/sidebar";
import Utility, {
  getCapitalizedTextForModuleName,
} from "../../utility/Utility";
import SubscriptionManager from "../../managers/SubscriptionManager";
import CompanyDetailManager from "../../managers/CompanyDetailManager";
import { TALK_TO_SALES_URL_MRP } from "../../constants/Constant";

interface BillingProps {
  billingData: any;
}

const Billing = (props: BillingProps) => {
  function getHeader() {
    return (
      <div className="row parent-width justify-content-between align-items-start">
        <div className="column">
          <DKLabel text="Bills" className="fw-m fs-l" />
          <DKLabel
            className="text-gray mt-s"
            text={`<b>Page refresh time:</b> ${getDateAsString(
              new Date(),
              "EEE, d MMM yyyy, HH:mm aa"
            )} `}
          />
        </div>

        {/* <div className="row width-auto" style={{ gap: 8 }}>
          <DKButton
            title="Download all to CSV"
            icon={DKIcons.ic_download}
            className="bg-white border-m"
          />
          <DKButton
            title="Print"
            icon={DKIcons.ic_printer}
            className="bg-white border-m"
          />
          <DKButton
            title="Billing Period: July 2024"
            icon={DKIcons.white.ic_arrow_down2}
            className="bg-blue text-white fw-m border-m"
            isReverse={true}
          />
          <DKButton
            title=""
            icon={DKIcons.ic_settings}
            className="bg-white border-m"
          />
        </div> */}
      </div>
    );
  }

  function getBillSummary() {
    const getCurrentBillingPeriod = () => {
      if (
        Utility.isEmpty(props.billingData?.NextChargeDate) || (
        !props.billingData?.CreditsApplied
      )) {
        return '-'
      }
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const startDate = new Date(props.billingData?.NextChargeDate);
      startDate.setMonth(startDate.getMonth() - 1);
      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth();
      const startMonthStr = monthNames[startMonth];
      const startYear = startDate.getFullYear();

      const endDate = new Date(props.billingData?.NextChargeDate);
      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth();
      const endMonthStr = monthNames[endMonth];
      const endYear = endDate.getFullYear();

      let dateRangeStr = '';
      if (startYear === endYear) {
        dateRangeStr = `${startMonthStr} ${startDay} - ${endMonthStr} ${endDay}, ${endYear}`;
      } else {
        dateRangeStr = `${startMonthStr} ${startDay}, ${startYear} - ${endMonthStr} ${endDay}, ${endYear}`;
      }

      return dateRangeStr;
    };

    return (
      <div className="column parent-width pt-l">
        <div className="column parent-width flex-1 bg-white border-m border-radius-s p-r">
          <div className="row justify-content-between">
            <DKLabel text="Estimated bill summary" className="fw-m" />

            {/* <DKButton
              title=""
              icon={DKIcons.ic_settings}
              className="bg-white border-m"
            /> */}
          </div>
          <DKLabel
            className="text-gray"
            text="Total charges and payment information"
          />

          <DKLine className="mt-l" style={{ width: "100%", height: 2 }} />

          <div className="row parent-width mt-l">
            <div className="column" style={{ flexBasis: "33%", gap: 8 }}>
              <DKLabel text="Org Name" className="text-dark-gray fw-m" />
              <DKLabel text={CompanyDetailManager.getCompanyName()} />
            </div>
            <div className="column" style={{ flexBasis: "33%", gap: 8 }}>
              <DKLabel text="Billing Period" className="text-dark-gray fw-m" />
              <DKLabel text={getCurrentBillingPeriod()} />
            </div>
            <div className="column" style={{ flexBasis: "33%", gap: 8 }}>
              <DKLabel text="Bill Status" className="text-dark-gray fw-m" />
              <DKLabel text="Pending" className="text-red border-red border-radius-l p-v-xs p-h-s" />
            </div>
          </div>

          <DKLine className="mt-l" style={{ width: "100%", height: 2 }} />

          <DKLabel
            className="text-gray mt-l align-self-end"
            text={`Total in ${SubscriptionManager.getCurrencySameAsActivePlanCurrency()}`}
          />
          <DKLabel
            className="fw-m mt-r align-self-end"
            text={`${SubscriptionManager.getCurrencySameAsActivePlanCurrency()} ${
              props?.billingData?.PricingInfo?.totalEstCost || 0
            }`}
          />

          <DKLine className="mt-l" style={{ width: "100%", height: 2 }} />

          <DKLabel
            className="fw-b fs-l mt-l align-self-end"
            text={`Estimated Grand Total: ${SubscriptionManager.getCurrencySameAsActivePlanCurrency()} ${
              props?.billingData?.PricingInfo?.totalEstCost || 0
            }`}
          />
        </div>
      </div>
    );
  }

  function getRowData() {
    let rowData: any[] = [];
    const pricingBreakDown = props.billingData?.PricingInfo?.pricing;
    if (!Utility.isEmpty(pricingBreakDown)) {
      pricingBreakDown?.forEach((pricingDetail: any) => {
        rowData.push({
          module: "Number of " + getCapitalizedTextForModuleName(pricingDetail?.module, "_"),
          currentCount: pricingDetail?.currentCount,
          estCost: pricingDetail?.estCost,
          rowButtons: pricingDetail?.talkToSales
            ? [
                {
                  className: "text-white bg-blue fw-m p-v-s",
                  title: "Talk to Sales",
                  onClick: () => {
                    window?.open(TALK_TO_SALES_URL_MRP, "_blank");
                  },
                },
              ]
            : [],
        });
      });
    }
    return rowData;
  }

  function getBreakdownGrid() {
    const columnConfig = [
      {
        key: "module",
        name: "Module",
        columnCode: "module",
        type: "text",
        editable: false,
        width: 400,
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={obj?.rowData?.module}
            />
          );
        },
      },
      {
        key: "currentCount",
        name: "Current Count",
        columnCode: "currentCount",
        type: "text",
        editable: false,
        width: 200,
        textAlign: "right",
      },
      {
        key: "estCost",
        name: "Estimated Cost",
        columnCode: "estCost",
        type: "text",
        editable: false,
        width: 200,
        textAlign: "right",
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={`${SubscriptionManager.getCurrencySameAsActivePlanCurrency()} ${
                obj?.rowData?.estCost
              }`}
              className="row justify-content-end"
            />
          );
        },
      },
      {
        key: "actions",
        name: "",
        type: INPUT_TYPE.BUTTON,
        width: 150,
      },
    ];

    return (
      <div className="column flex-1 parent-width p-v-l">
        <div className="column parent-width flex-1 bg-white border-m border-radius-s p-r">
          <DKDataGrid
            title={`<span class="fw-m text-orange">Breakdown</span>`}
            columns={columnConfig}
            rows={getRowData()}
            needShadow={false}
            width={SideBarService.getContainerWidth() - 100}
            needTrailingColumn={true}
            allowColumnUpdate={false}
            allowColumnShift={false}
            allowRowUpdate={false}
            allowBulkOperation={false}
            needColumnIcons={false}
            allowColumnSort={false}
            needBorder={true}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="column parent-size" style={{ overflow: "auto" }}>
      {getHeader()}
      {getBillSummary()}
      {getBreakdownGrid()}
    </div>
  );
};

export default Billing;
