import { FC, useState } from 'react';
import {
	DKIcon,
	DKIcons,
	DKButton,
	DKLabel,
	DKInput,
	INPUT_VIEW_DIRECTION,
	INPUT_TYPE,
} from 'deskera-ui-library';
import CreditCardView from '../common/CreditCardView';

interface IAddCardProps {
	onSave: (card: any) => void;
	onCancel: () => void;
}

const AddCard: FC<IAddCardProps> = (props) => {

	return (
		<div className="transparent-background">
			<div
				className="popup-window p-0 position-relative bg-dark-gray"
				style={{
					width: 400,
					overflowY: 'hidden',
				}}
			>
				<CreditCardView
					isPopup={true}
					onCardAdded={props.onSave}
					onCancel={props.onCancel}
				/>
			</div>
		</div>
	);
};

export default AddCard;
