import React, { Component } from "react";

import {
  DKLabel,
  DKButton,
  DKInput,
  showLoader,
  removeLoader,
} from "deskera-ui-library";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import Subscription from "../../../services/subscription";

class AcceptPhoneNumberPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileValue: "",
      checkTapped: false,
      showOtpPopup: false,
      otp: "",
      sendOtpTapped: false,
      isInvalidNumber: false,
    };
  }
  render() {
    const { t } = this.props;
    return (
      <div className=" transparent-background">
        <div className="popup-window" style={{ maxWidth: 500 }}>
          {this.getHeader()}
          {this.state.showOtpPopup
            ? this.props.isPopup
              ? null
              : this.getMobileNumberForm()
            : this.getMobileNumberForm()}
          {this.state.showOtpPopup && this.getOtpPopup()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between">
        <div>
          <div className="row">
            {/* <DKIcon src={ic_coupons} className="mr-s ic-s-2" /> */}
            <DKLabel text={`Enter Mobile Number`} className="fw-m fs-l" />
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title="Close"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onClose();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getMobileNumberForm() {
    return (
      <div className="column mt-xl mb-l">
        <DKLabel text={`Enter your mobile number below`} />
        <div className="row mt-m" style={{ alignItems: "flex-start" }}>
          <div>
            <DKInput
              style={{ width: "60px", marginRight: "4px" }}
              needHeader={false}
              name="Country code"
              disabled
              value="+ 91"
            />
          </div>
          <DKInput
            needHeader={false}
            name={"Mobile Number"}
            placeholder={"Enter your mobile number"}
            required={true}
            value={this.state.mobileValue}
            onChange={(value, evt) => {
              const newValue = evt.target.validity.valid
                ? evt.target.value
                : this.state.mobileValue;
              this.setState({
                mobileValue: newValue,
              });
            }}
            canValidate={this.state.checkTapped}
            invalid={
              this.state.checkTapped &&
              (this.state.mobileValue === "" ||
                this.state.mobileValue.length !== 10)
            }
            maxLength={"10"}
            pattern="[0-9]*"
            style={{}}
          />
          <DKButton
            className="text-wrap-none text-white bg-green fw-m ml-m mt-xs"
            title="Save"
            onClick={this.addPhoneNumber}
          />
        </div>
      </div>
    );
  }

  addPhoneNumber = () => {
    this.setState({
      checkTapped: true,
      showOtpPopup: false,
      otp: "",
    });
    if (
      this.state.mobileValue.trim() !== "" &&
      this.state.mobileValue.trim().length === 10
    ) {
      showLoader(t("SAVING_DETAILS"));
      Subscription.addPhoneNumber(this.state.mobileValue.trim())
        .then(
          (res) => {
            removeLoader();
            this.setState({
              showOtpPopup: true,
            });
          },
          (err) => {
            removeLoader();
          }
        )
        .catch((error) => {
          console.log("error addPhoneNumber", error);
        });
    }
  };
  ////////////////////////////////////////////////////////////////////////////
  getOtpPopup = () => {
    return (
      <div className="column parent-width mt-xxl mb-l">
        <DKLabel
          text="Enter your OTP here"
          className="fw-m fs-m text-gray mb-s"
        />
        <DKInput
          needHeader={false}
          name={"Otp"}
          type="password"
          placeholder={"Enter your OTP"}
          required={true}
          value={this.state.otp}
          onChange={(value) => {
            this.setState({
              otp: value,
            });
          }}
          canValidate={this.state.sendOtpTapped}
          invalid={this.state.sendOtpTapped && this.state.otp === ""}
        />
        <div className="row mt-xxl">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m fw-m mr-r"
            onClick={this.cancelTapped}
          />
          <DKButton
            title="Send Otp"
            className="bg-blue  fw-m text-white"
            onClick={this.sendOtpRequest}
          />
        </div>
      </div>
    );
  };

  sendOtpRequest = () => {
    this.setState({
      sendOtpTapped: true,
    });
    if (this.state.otp.trim() !== "") {
      showLoader(t("SUBMITTING_OTP_DETAILS"));
      Subscription.getOtpForCashfree(this.state.otp.trim()).then(
        (resOtp) => {
          removeLoader();
          this.props.onSave();
        },
        (errOtp) => {
          removeLoader();
        }
      );
    }
  };
  ////////////////////////////////////////////////////////////////////////////
}

export default withTranslation()(AcceptPhoneNumberPopup);
