import ApiConstants from "../constants/ApiConstants"
import httpClient  from "../http"
import { BillingCycleModuleUsage, ModulesUsageResponse } from "../model/CreditPlanDetails";
export const checkCode = (payload:any) => {
    return httpClient.post(ApiConstants.URL.CREDIT.CHECK_CODE, payload);
}
export const applyCode = (payload:any) => {
    return httpClient.post(ApiConstants.URL.CREDIT.APPLY, payload);
}
export const getPricing = (payload:any) => {
    return httpClient.post(ApiConstants.URL.CREDIT.PRICING, payload);
}
export const getBillingDetails = () => {
    return httpClient.get(ApiConstants.URL.CREDIT.BILLING_DETAILS);
}
export const getMostRecentDeductedModuleUsage = () => {
    return httpClient.get(ApiConstants.URL.CREDIT.RECENT_MODULE_USAGE);
}
export const getCurrentBillingCycleModuleUsage = (): Promise<BillingCycleModuleUsage> => {
    return httpClient.get(ApiConstants.URL.CREDIT.CURENT_BILLING_CYCLE_MODULE_USAGE);
}
export const getModuleUsageByDateRange = (fromDate: string, toDate: string, summarized = false): Promise<BillingCycleModuleUsage> => {
    return httpClient.get(ApiConstants.URL.CREDIT.DATE_RANGE_MODULE_USAGE(fromDate, toDate, summarized));
}
export const getModuleUsageForTrailingMonths = (trailingMonths: number) => {
    return httpClient.get(ApiConstants.URL.CREDIT.TRAILING_MONTHS_FROM_CURRENT_CYCLE_MODULE_USAGE(trailingMonths));
}
