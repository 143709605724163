import { FC, useEffect, useState } from 'react';
import {
	DKIcon,
	DKIcons,
	DKLabel,
	DKButton,
	DKInput,
	INPUT_VIEW_DIRECTION,
	showToast,
	showAlert,
	showLoader,removeLoader} from 'deskera-ui-library';
import ic_visa_logo from '../../assets/icons/visa-logo.svg';
import ic_mastercard_logo from '../../assets/icons/mastercard-logo.svg';
import ic_credit_card from '../../assets/icons/ic_credi_card.png';
import ic_cc_generic_logo from '../../assets/icons/generic_cc_logo.svg';
import AddCard from './AddCard';
import Subscription from '../../services/subscription';
import Utility, { getCapitalized } from '../../utility/Utility';
import { ALERT_MESSAGES, AUTO_CHARGE_CONFIG_PLACEHOLDER, AUTO_CHARGE_CONFIG_TITLE } from '../../constants/Constant';
import SubscriptionManager from '../../managers/SubscriptionManager';
import BillingHistory from '../common/BillingHistory';

interface ICardAndPaymentsProps {
	billingData?: any;
}

const getLogo = (type: string) => {
	switch (type) {
		case 'visa':
			return ic_visa_logo;
		case 'mastercard':
			return ic_mastercard_logo;
		default:
			return ic_cc_generic_logo;
	}
};

const CardAndPayments: FC<ICardAndPaymentsProps> = (props) => {
	const [showAddCard, toggleShowAddCard] = useState<boolean>(false);
	const [paymentInfo, setPaymentInfo] = useState<any>({});
	const [autoChargeConfig, setAutoChargeConfig] = useState<any>({
		enabled: false,
		thresholdLevel: 0,
		thresholdAlertLevel: 0,
		rechargeAmount: 10
	});
	const hasCards = !Utility.isEmpty(paymentInfo?.Cards);

	useEffect(() => {
		fetchPaymentMethods();
	}, []);

	const isValidInput = (val: number) => {
		return val>=10;
	}

	const autoChargeIsAllowed = () => {
		return hasCards && autoChargeConfig?.enabled;
	}

	const fetchPaymentMethods = async () => {
		const response: any = await Subscription.getPaymentMethods();
		if (!Utility.isEmpty(response)) {
			setPaymentInfo(response ?? {});
			setAutoChargeConfig({
				enabled: response?.AutoChargeConfig?.Enabled,
				thresholdLevel: response?.AutoChargeConfig?.ThresholdLevel,
				thresholdAlertLevel: response?.AutoChargeConfig?.ThresholdAlertLevel,
				rechargeAmount: response?.AutoChargeConfig?.RechargeAmount
			});
		}
	};
	const renderCard = (card: any, index: number) => {
		return (
			<div
				className="row p-r border-m border-radius-s align-items-center"
				style={{ gap: 20 }}
				key={card.PaymentMethodID}
			>
				<DKIcon
					src={getLogo(card.CardBrand)}
					style={{ width: 60 }}
					className={'ic-m border-m p-h-xs border-radius-s column'}
				/>
				<div className="column parent-width">
					<DKLabel
						text={`${getCapitalized(card.CardBrand)} ending with ${
							card.Last4
						}`}
						className={'fw-b row'}
						style={{ textWrap: 'nowrap' }}
					/>
					<DKLabel
						text={`Exp. date ${card.ExpMonth}/${card.ExpYear}`}
						className={`text-gray fs-s mt-xs`}
					/>
				</div>
				{paymentInfo?.DefaultPaymentMethodID ===
					card?.PaymentMethodID ? (
					<div className="column parent-width">
						<DKLabel
							text={`Default`}
							className={`bg-blue text-white p-v-xs p-h-s border-radius-m align-self-end`}
						/>
					</div>
				) : (
					<DKButton
						title="Set as default"
						className="text-blue cursor-hand"
						style={{
								display:"inline-flex"
						}}
						onClick={() => {
							showAlert('Confirm', `Are you sure you want to set card ending with ${card?.Last4} as default?`, [
								{
									title: "No",
									className: "bg-gray2",
								},
								{
									title: "Yes",
									className: "bg-blue mr-r text-white",
									onClick: async () => {
										const response: any = await Subscription.updateDefaultPaymentMethod({paymentMethodID: card?.PaymentMethodID});
										if(response) {
											fetchPaymentMethods();
										}
									}
							}
							])
						}}
					/>
				)}
			</div>
		);
	};

	const renderCardSection = () => {
		return (
			<div
				id="card-section"
				className="card-section row bg-white p-l border-radius-m align-items-start border-m"
			>
				<>
					<div
						className="column justify-content-center"
						style={{ width:'30%' }}
					>
						<div className="row">
							<DKIcon src={ic_credit_card} className={'ic-r'} />
							<DKLabel
								text={'Credit card(s)'}
								className={'fs-xl fw-b'}
							/>
						</div>
						<div className="row m-v-m">
							<DKLabel
								text={
									'Manage your credit cards and payment options'
								}
								className={'text-gray'}
							/>
						</div>
						{hasCards && <div className="row mt-m">
							<DKButton
								title="Add new card"
								className="bg-blue border-radius-s p-m text-white justify-content-center"
								style={{ width: 150 }}
								onClick={() => {
									toggleShowAddCard(true);
								}}
							/>
						</div>}
					</div>

					{!hasCards && renderEmptyView()}
					{hasCards && <div className="column" style={{ gap: 10, width: 'auto' }}>
						{paymentInfo?.Cards?.map(renderCard)}
					</div>}
				</>
			</div>
		);
	};

	const handleAutoChargeDetailsUpdate = async (payload: any, showUpdateAlert: boolean = false) => {
		if(isValidInput(payload?.rechargeAmount)) {
			const response: any = await Subscription.updateAutochargeDetails(payload);
			if(response) {
				showUpdateAlert && showAlert(ALERT_MESSAGES.SUCCESS, ALERT_MESSAGES.TEXT_SUCCESS, [{
					title: "Ok",
					className: "mr-r bg-gray2",
				}]);
				fetchPaymentMethods();
			} else {
				showUpdateAlert && showAlert(ALERT_MESSAGES.FAILURE, ALERT_MESSAGES.TEXT_FAILURE, [{
					title: "Ok",
					className: "mr-r bg-red text-white",
				}]);
			}
		} 
	}

	const getTextField = ({title, value, placeholder, onChange, validator}: {
		title: string, 
		value: number, 
		placeholder: string, 
		onChange: any,
		validator: any
	}) => {
		const currency = SubscriptionManager.getCurrencySameAsActivePlanCurrency();
		return (
			<div className='row m-v-l'>
				<DKLabel text={title + ' (' + currency + ')'} style={{ minWidth: 300}} />
				<div className='row width-auto align-items-center justify-content-center'>
					<div style={{ minWidth: 150 }}>
						<DKInput 
							canValidate
        			errorMessage="Please enter a value greater than or equal to 10"
							placeholder={placeholder}
							className="fs-m" 
							type="number"
							value={value}
							onChange={onChange}
							direction={INPUT_VIEW_DIRECTION.VERTICAL}
							textAlign="right"
							validator={validator}
						/>
					</div>
				</div>
			</div>
		)
	}

	const renderAutoPay = () => {
		return (
			<div
				id="auto-pay-section"
				className="auto-pay-section column bg-white p-l border-radius-m align-items-start border-m mt-l"
			>
				<div className="row">
					<DKIcon src={DKIcons.ic_repeat} className={'ic-s'} />
					<DKLabel
						text={`Auto recharge is ${autoChargeIsAllowed() ? 'on' : 'off'}`}
						className={'fs-l fw-b ml-s'}
					/>
				</div>
				<div className="row">
					<DKLabel
						text={`When your credit balance reaches 0, your application will stop accepting request. Enable automatic recharge to automatically keep your credit balance topped up.`}
						className={'fs-m ml-xl mt-s'}
					/>
				</div>
				<div
					className="row width-auto"
					onClick={async () => {
						if(hasCards) {
							autoChargeConfig?.enabled && handleAutoChargeDetailsUpdate({ ...autoChargeConfig, enabled: !autoChargeConfig?.enabled});
							setAutoChargeConfig((prev: any) => ({ ...prev, enabled: !prev?.enabled}))
						} else {
							showAlert("Alert!", "Please add credit card to enable auto-recharge")
						}
					}}
				>
					<DKLabel
						text={`${
							autoChargeIsAllowed() ? 'Disable' : 'Enable'
						} auto recharge`}
						className={`cursor-hand fw-b fs-m ml-xl mt-s text-underline ${
							autoChargeIsAllowed() ? 'text-red' : 'text-blue'
						}`}
					/>
				</div>
				{
					autoChargeIsAllowed() && 
					<>
						<div
							className="parent-width ml-xl"
						>
							{getTextField({
									title: AUTO_CHARGE_CONFIG_TITLE.CREDIT_THRESHOLD, 
									value: autoChargeConfig?.thresholdLevel, 
									placeholder: AUTO_CHARGE_CONFIG_PLACEHOLDER.CREDIT_THRESHOLD, 
									onChange: (e:string) => setAutoChargeConfig((prevState: any) => ({ ...prevState, thresholdLevel: +e})),
									validator: () => true
								})
							}
							{getTextField({
									title: AUTO_CHARGE_CONFIG_TITLE.ALERT_THRESHOLD, 
									value: autoChargeConfig?.thresholdAlertLevel, 
									placeholder: AUTO_CHARGE_CONFIG_PLACEHOLDER.ALERT_THRESHOLD, 
									onChange: (e:string) => setAutoChargeConfig((prevState: any) => ({ ...prevState, thresholdAlertLevel: +e})),
									validator: () => true
								})
							}
							{getTextField({
									title: AUTO_CHARGE_CONFIG_TITLE.RECHARGE_AMOUNT, 
									value: autoChargeConfig?.rechargeAmount, 
									placeholder: AUTO_CHARGE_CONFIG_PLACEHOLDER.RECHARGE_AMOUNT, 
									onChange: (e:string) => setAutoChargeConfig((prevState: any) => ({ ...prevState, rechargeAmount: +e})),
									validator: (val: any) => isValidInput(val)
								})
							}
						</div>
						<DKButton
							title="Save"
							className={`bg-gray1 border-m ml-xl ${isValidInput(autoChargeConfig?.rechargeAmount) ? 'bg-blue text-white' : ''}`}
							style={{display: 'inline-flex'}}
								onClick={async () => handleAutoChargeDetailsUpdate(autoChargeConfig, true)}
						/>
					</>
				}
			</div>
		);
	};

	const renderEmptyView = () => {
		return (
			<div
				id="cards_payment_empty_view"
				className="column width-auto align-items-center justify-content-center  border-m border-radius-r p-m"
				style={{ gap: 25 }}
			>
				<div
					className="row align-items-center justify-content-center"
					style={{ gap: 15 }}
				>
					<DKIcon
						src={ic_mastercard_logo}
						className={'ic-m p-h-s border-s border-radius-m'}
					/>
					<DKIcon
						src={ic_visa_logo}
						className={'ic-m p-h-s border-s border-radius-m'}
					/>
				</div>
				<div className="row align-items-center justify-content-center">
					<DKLabel
						text={`Add your first credit card for payments`}
						className={`fs-xxl fw-b`}
						style={{ maxWidth: 400, textAlign: 'center' }}
					/>
				</div>
				<div className="row align-items-center justify-content-center">
					<DKLabel
						text={`This credit card will be used by default for billing.`}
						className={`fs-m fw-b text-gray`}
						style={{ maxWidth: 400, textAlign: 'center' }}
					/>
				</div>
				<div className="row align-items-center justify-content-center">
					<DKButton
						title="Add new card"
						className="bg-blue border-radius-s p-m text-white justify-content-center"
						style={{ width: 150 }}
						onClick={() => {
							toggleShowAddCard(true);
						}}
					/>
				</div>
			</div>
		);
	};

	const renderBillingHistory = () => {
		return (
			<div
				id="billing-history-section"
				className="billing-history-section column bg-white border-radius-m align-items-start mt-l"
			>
				<BillingHistory />
			</div>
		)
	}
	return (
		<div
			id="payment-action"
			className="card-payment-wrapper parent-width align-items-start"
		>
			{renderCardSection()}
			{renderAutoPay()}
			{showAddCard && (
				<AddCard
					onSave={(card) => {
						toggleShowAddCard(false);
						fetchPaymentMethods();
					}}
					onCancel={() => {
						toggleShowAddCard(false);
					}}
				/>
			)}
			{renderBillingHistory()}
		</div>
	);
};

export default CardAndPayments;
