import {
  DKDateRangePicker,
  DKDataGrid,
  DKButton,
  DKCalendar,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
} from "deskera-ui-library";
import SideBarService from "../../services/sidebar";
import { useEffect, useState } from "react";
import { getModuleUsageByDateRange } from "../../services/credit";
import { BillingCycleModuleUsage } from "../../model/CreditPlanDetails";
import { DURATION } from "../../constants/Constant";
import moment from "moment";
import { getCapitalizedTextForModuleName } from "../../utility/Utility";

const DURATIONS = [
  { label: "Day", value: DURATION.DAY },
  { label: "Month", value: DURATION.MONTH },
  { label: "Custom", value: DURATION.CUSTOM },
];

const CreditUsage = (props: any) => {
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
  const [duration, setDuration] = useState(DURATIONS[1]);
  const [fromDate, setFromDate] = useState<string>(startOfMonth);
  const [toDate, setToDate] = useState<string>(endOfMonth);
  const [showDayCalender, setShowDayCalender] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [moduleUsageData, setModuleUsageData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadModuleUsage(fromDate, toDate);
  }, []);

  const loadModuleUsage = async (from: string, to: string) => {
    setIsLoading(true);
    try {
      const response = await getModuleUsageByDateRange(from, to, true);
      parseModuleUsageData(response);
    } catch (err: any) {
      console.error("Error loading usage data: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  const parseModuleUsageData = (data: BillingCycleModuleUsage) => {
    const rows: any[] = [];
    data.ModuleTotal?.forEach((record) => {
      rows.push({
        Module: record?.Module || "",
        Count: record?.Count || 0,
        CreditsDeducted: record?.CreditsDeducted || 0,
      });
    });
    // Sort rows by Module name
    rows.sort(function (a, b) {
      return a.Module > b.Module ? 1 : b.Module > a.Module ? -1 : 0;
    });

    if (rows.length) {
      const total = data.TotalCredits || 0;
      rows.push({
        Module: "",
        Count: "<span class='fw-m'>Total</span>",
        CreditsDeducted: `<span class='fw-m'>${total}</span>`,
        isTotal: true,
      });
    }
    setModuleUsageData(rows);
  };

  const getColumnConfig = () => {
    return [
      {
        key: "Module",
        name: "Module",
        columnCode: "Module",
        type: "text",
        editable: false,
        width: 400,
        renderer: (obj: any) => {
          if (obj?.rowData?.Module !== "AI") {
            return <DKLabel text={getCapitalizedTextForModuleName(obj?.rowData?.Module, "_")} />;
          }
          return <DKLabel text={obj?.rowData?.Module} />;
        },
      },
      {
        key: "Count",
        name: "Current Count",
        columnCode: "Count",
        type: "text",
        editable: false,
        width: 200,
        textAlign: "right",
      },
      {
        key: "CreditsDeducted",
        name: "Credits Deducted",
        columnCode: "CreditsDeducted",
        type: "text",
        editable: false,
        width: 200,
        textAlign: "right",
        renderer: (obj: any) => {
          return <DKLabel text={obj?.rowData?.CreditsDeducted} className="row justify-content-end" />;
        },
      },
    ];
  };

  const getAllUsageGrid = () => {
    return (
      <DKDataGrid
        title=""
        rows={moduleUsageData}
        width={SideBarService.getContainerWidth() - 55}
        columns={getColumnConfig()}
        allowBulkOperation={false}
        allowColumnEdit={false}
        needShadow={false}
        needBorder={true}
        needTrailingColumn={true}
        needColumnIcons={false}
        allowColumnSort={false}
        needNoDataView={true}
      />
    );
  };

  const getDurationField = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        value={duration?.label || ""}
        title=""
        readOnly={isLoading}
        onChange={(obj: any) => {
          if (obj.value === DURATION.DAY) {
            const lastDay = moment().format("YYYY-MM-DD");
            setFromDate(lastDay);
            setToDate(lastDay);
            loadModuleUsage(lastDay, lastDay);
          }
          if (obj.value === DURATION.MONTH || obj.value === DURATION.CUSTOM) {
            setFromDate(startOfMonth);
            setToDate(endOfMonth);
            loadModuleUsage(startOfMonth, endOfMonth);
          }
          if (obj.value === DURATION.CUSTOM) {
            setShowDateRangePicker(true);
          }
          setDuration({ ...obj });
        }}
        style={{ width: 150 }}
        dropdownConfig={{
          title: "",
          allowSearch: false,
          searchableKey: "label",
          style: { minWidth: 230, top: 0 },
          className: "shadow-m",
          data: DURATIONS,
          renderer: (i: number, obj: any) => {
            return <DKLabel text={`${obj.label}`} />;
          },
          onSelect: (i: number, obj: any) => {},
        }}
      />
    );
  };

  /* Month selector */
  const getNextMonth = () => {
    const nextMonthFromDate = moment(fromDate).add(1, "months").startOf("month").format("YYYY-MM-DD");
    const nextMonthToDate = moment(toDate).add(1, "months").endOf("month").format("YYYY-MM-DD");
    setFromDate(nextMonthFromDate);
    setToDate(nextMonthToDate);
    loadModuleUsage(nextMonthFromDate, nextMonthToDate);
  };

  const getPrevMonth = () => {
    const prevMonthFromDate = moment(fromDate).subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    const prevMonthToDate = moment(toDate).subtract(1, "months").endOf("month").format("YYYY-MM-DD");
    setFromDate(prevMonthFromDate);
    setToDate(prevMonthToDate);
    loadModuleUsage(prevMonthFromDate, prevMonthToDate);
  };

  const getMonthSelector = () => {
    const monthStr = moment(fromDate).format("MMM YYYY");
    return (
      <div className="row width-auto">
        <DKButton
          icon={DKIcons.ic_arrow_left}
          className={`cursor-hand mr-s ${isLoading ? "opacity-3" : "opacity-6"}`}
          style={{ padding: "0px 2px" }}
          onClick={() => getPrevMonth()}
          disabled={isLoading}
        />
        <div className="row width-auto justify-content-center" style={{ width: 63 }}>
          <DKLabel text={monthStr} className={`${isLoading ? "opacity-6" : ""}`} />
        </div>
        <DKButton
          icon={DKIcons.ic_arrow_right}
          className={`ml-s mr-r cursor-hand ${isLoading ? "opacity-3" : "opacity-6"}`}
          style={{ padding: "0px 2px" }}
          onClick={() => getNextMonth()}
          disabled={isLoading}
        />
      </div>
    );
  };
  /* Month selector ends */

  /* Day selector */
  const getPrevDay = () => {
    const prevDate = moment(fromDate).subtract(1, "days").format("YYYY-MM-DD");
    setFromDate(prevDate);
    setToDate(prevDate);
    loadModuleUsage(prevDate, prevDate);
  };

  const getNextDay = () => {
    const nextDate = moment(fromDate).add(1, "days").format("YYYY-MM-DD");
    setFromDate(nextDate);
    setToDate(nextDate);
    loadModuleUsage(nextDate, nextDate);
  };

  const getDaySelector = () => {
    const dayDateStr = moment(fromDate)?.format("DD MMM YYYY");
    return (
      <div className="row width-auto">
        <DKButton
          icon={DKIcons.ic_arrow_left}
          className={`cursor-hand mr-s ${isLoading ? "opacity-3" : "opacity-6"}`}
          style={{ padding: "0px 2px" }}
          onClick={() => getPrevDay()}
          disabled={isLoading}
        />
        <div className="row width-auto position-relative" style={{ width: 85 }}>
          <DKButton
            title={dayDateStr}
            style={{ padding: "0px 4px" }}
            onClick={() => {
              setShowDayCalender(true);
            }}
            className={`${isLoading ? "opacity-6" : ""}`}
            disabled={isLoading}
          />
          {showDayCalender && (
            <DKCalendar
              className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
              style={{ right: 0, top: 20 }}
              selectedDate={moment(fromDate).toDate()}
              onSelectDate={(newDate: Date) => {
                const dateStr = moment(newDate).format("YYYY-MM-DD");
                setFromDate(dateStr);
                setToDate(dateStr);
                loadModuleUsage(dateStr, dateStr);
                setShowDayCalender(false);
              }}
              onClose={() => setTimeout(() => setShowDayCalender(false))}
            />
          )}
        </div>
        <DKButton
          icon={DKIcons.ic_arrow_right}
          className={`ml-s mr-r cursor-hand ${isLoading ? "opacity-3" : "opacity-6"}`}
          style={{ padding: "0px 2px" }}
          onClick={() => getNextDay()}
          disabled={isLoading}
        />
      </div>
    );
  };
  /* Day selector ends */

  const getCustomSelector = () => {
    const startDate = moment(fromDate).toDate();
    const endDate = moment(toDate).toDate();
    const startDateStr = moment(fromDate)?.format("DD MMM YYYY");
    const toDateStr = moment(toDate)?.format("DD MMM YYYY");
    return (
      <div className="row width-auto position-relative mr-r" style={{ width: 170 }}>
        <DKButton
          title={`${startDateStr} - ${toDateStr}`}
          style={{ padding: "0px 4px" }}
          onClick={() => {
            setShowDateRangePicker(true);
          }}
          disabled={isLoading}
          className={`${isLoading ? "opacity-6" : ""}`}
        />
        {showDateRangePicker && (
          <div className="position-absolute bg-white z-index-3" style={{ top: 25, right: 0 }}>
            <DKDateRangePicker
              className=" border-radius-s shadow-m border-box"
              onClose={() => {
                setShowDateRangePicker(false);
              }}
              color={"rgb(203, 26, 38)"}
              startDate={startDate}
              selectedStartDate={startDate}
              selectedEndDate={endDate}
              showPresetList={true}
              onSelectDateRange={(startDate: Date, endDate: Date) => {
                if (startDate && endDate) {
                  const fromDateStr = moment(startDate).format("YYYY-MM-DD");
                  const endDateStr = moment(endDate).format("YYYY-MM-DD");
                  setFromDate(fromDateStr);
                  setToDate(endDateStr);
                  loadModuleUsage(fromDateStr, endDateStr);
                  setShowDateRangePicker(false);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const getDateRangeDetails = () => {
    switch (duration?.value) {
      case DURATION.DAY:
        return getDaySelector();
      case DURATION.MONTH:
        return getMonthSelector();
      case DURATION.CUSTOM:
        return getCustomSelector();
      default:
        return <></>;
    }
  };

  const getGridHeader = () => {
    return (
      <div className="row justify-content-between align-items-center">
        <div className="row width-auto">
          <DKLabel text="Usage By Module" className="fw-m fs-m" />
          {isLoading && <DKSpinner className="ml-r" iconClassName="ic-r" />}
        </div>
        <div className="row width-auto">
          <div className="column mr-m position-relative">{getDateRangeDetails()}</div>
          <div className="column" style={{ width: 100 }}>
            {getDurationField()}
          </div>
        </div>
      </div>
    );
  };

  const getBlankGridView = () => {
    return (
      <div className="column parent-size justify-content-center align-items-center mb-l">
        <DKLabel text="No data found" className="fw-m text-gray" />
      </div>
    );
  };

  return (
    <div className="column parent-width p-r border-m border-radius-m mt-l bg-white" style={{ minHeight: 300 }}>
      {getGridHeader()}
      {getAllUsageGrid()}
      {moduleUsageData?.length === 0 && getBlankGridView()}
    </div>
  );
};

export default CreditUsage;
